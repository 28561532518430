/*
 ProfileMetadataModel is a 'model' for the data stored in the
 user profile. A component can register as a listener for an item of metadata.
*/
export class ProfileMetadataModel {
  // srcElement is the element containing the js that defines the pre-set values
  // for the metadata
  constructor(srcInput) {
    this.listeners = {};
    this.srcInput = srcInput;
    const json = srcInput.value;
    if (`` == json) {
      this.js = {};
    } else {
      this.js = JSON.parse(json);
    }
  }
  addListener(key, listener) {
    var listeners = this.listeners[key];
    if (listeners) {
      listeners.push(listener);
    } else {
      this.listeners[key] = [listener];
    }
  }
  getValue(key) {
    return this.js[key];
  }
  setValue(key, value) {
    this.js[key] = value;
    this.srcInput.value = JSON.stringify(this.js);
    console.log(`ProfileMetadataModel.srcInput.value = `, this.srcInput.value);
  }
}
