// dtemplate generated - do not edit
export const Template= (function() {	
	let templates =
		{"MetaSelect":"\u003ctemplate\u003e\n\t\u003cstyle\u003e:host{display:flex;flex-direction:column;justify-content:flex-start;align-items:stretch}label{font-size:0.8em;color:#666;margin-bottom:0.4em}\n\u003c/style\u003e\n\t\u003clabel for=\"field\" id=\"label\"\u003e\n\t\u003c/label\u003e\n\t\u003cselect id=\"field\"\u003e\n\t\u003c/select\u003e\n\u003c/template\u003e\n","ProfileMetadata":"\u003ctemplate\u003e\n\t\u003cstyle\u003e:host{display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;gap:1em;margin:1em 0}\n\u003c/style\u003e\n\t\u003cidserver-metaselect data=\"profile-metadata-json\" target=\"profile-metadata-values\" label=\"Province\" key=\"province\" value=\"\"\u003e \u003c/idserver-metaselect\u003e\n\t\u003cidserver-metaselect label=\"Gender\" key=\"gender\" value=\"\" data=\"profile-metadata-json\" target=\"profile-metadata-values\"\u003e \u003c/idserver-metaselect\u003e\n\t\u003cidserver-metaselect value=\"\" data=\"profile-metadata-json\" target=\"profile-metadata-values\" label=\"If you're a student, please select\" key=\"student\"\u003e \u003c/idserver-metaselect\u003e\n\t\u003cidserver-metaselect label=\"If you're no longer studying, are you\" key=\"employed\" value=\"\" data=\"profile-metadata-json\" target=\"profile-metadata-values\"\u003e \u003c/idserver-metaselect\u003e\n\u003c/template\u003e\n"};

	let mk = function(k, html) {
		let el = document.createElement('div');
		el.innerHTML = html;
		//console.log("mk(",k,") html = ", html);

		let c = el.firstElementChild;
		while ((null!=c) && (Node.ELEMENT_NODE!=c.nodeType)) {
			c = c.nextSibling;
		}
		if (null==c) {
			console.error("FAILED TO FIND ANY ELEMENT CHILD OF ", k, ":", el)
			return mk('error', '<em>No child elements in template ' + k + '</em>');
		}
		el = c;
		if ('function'==typeof el.querySelector) {
			let et = el.querySelector('[data-set="this"]');
			if (null!=et) {
				el = et;
				el.removeAttribute('data-set');
			}
		}
		return el;
	}

	return function(t, dest={}) {
		// Return a deep copy of the node, created on first use
		let n = templates[t];
		if ('string'==typeof(n)) {			
			n = mk(t, n);
			templates[t] = n;
		}
		if ('undefined'==typeof n) {
			console.error('Failed to find template ' + t);
			return [false,false];
		}
		if (n.content) {
			n = n.content.cloneNode(true);
		} else {
			n = n.cloneNode(true);
		}
		try {
			for (let attr of ['id', 'data-set']) {
				let nodes = Array.from(n.querySelectorAll('[' + attr + ']'));
				if ('function'==typeof n.hasAttribute && n.hasAttribute(attr)) {
					nodes.unshift(n);
				}
				for (let el of nodes) {
					let a = el.getAttribute(attr);
					if (a.substr(0,1)=='$') {
						a = a.substr(1);
						el = jQuery(el);
						el.setAttribute(attr, a);
					}
					dest[a] = el;
				}
			}
		} catch (err) {
			console.error("ERROR in DTemplate(" + t + "): ", err);
			debugger;
		}
		return [n,dest];
	}
})();
