import { Template } from "./Template.js";
import { ProfileMetadata } from "./ProfileMetadata";
/**
 * MetaSelect element contains a single dropdown selection
 * for metadata.
 * It requires keys:
 *   @key : the key for the data
 *   @label: the label for the data
 *   @data: A document-level element that will contain the data
 *     with a js encoded object where the key provides the relevant options for
 *     the data.
 *   @value: selected value to use
 *   @target: the id of the text field where the computed JS should be stored
 */
export class MetaSelect extends HTMLElement {
  constructor() {
    super();
    [this.el, this.$] = Template("MetaSelect");
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(this.el);
  }
  selectionChange(evt) {
    console.log(
      `selection changed to ${this.$.field.options[this.$.field.selectedIndex].value}`,
    );
    this.updateTarget();
  }
  connectedCallback() {
    ProfileMetadata.getModel(this).then((model) => {
      this.model = model;
      this.$.label.innerText = this.getAttribute(`label`);
      const value = this.model.getValue(this.getAttribute(`key`));
      console.log(
        `MetaSelete.connectedCallback() - value of ${this.getAttribute(`key`)} is ${value}`,
      );
      console.log(``);
      this.$.field.innerHTML = ``;
      let optionCount = 0;
      let setFromTarget = false;
      for (const v of this.options()) {
        const o = document.createElement(`option`);
        o.innerText = v;
        o.setAttribute(`value`, v);
        this.$.field.appendChild(o);
        if (value == v) {
          this.$.field.selectedIndex = optionCount;
          setFromTarget = true;
        }
        optionCount++;
      }
      if (!setFromTarget) this.updateTarget();
      this.$.field.addEventListener(`change`, (evt) => {
        this.selectionChange(evt);
      });
    });
  }
  options() {
    if (this.js) {
      return this.js;
    }
    const jsSrc = document.getElementById(this.getAttribute("data"));
    if (!jsSrc) {
      console.error(
        `No element #${this.getAttribute("data")} found in document for idserver-metaselect ${this.getAttribute("key")}`,
      );
      return [];
    }
    const js = JSON.parse(jsSrc.innerHTML);
    if (!js) {
      console.error(
        `Failed to parse JSON data for idserver-metaselect ${this.getAttribute(`key`)}`,
      );
      return [];
    }
    this.js = js[this.getAttribute(`key`)];
    return this.js;
  }
  updateTarget() {
    this.model.setValue(
      this.getAttribute(`key`),
      this.$.field.options[this.$.field.selectedIndex].value,
    );
  }
}

customElements.define("idserver-metaselect", MetaSelect);
