import { Template } from "./Template.js";
import { MetaSelect } from "./MetaSelect.js";
import { ProfileMetadataModel } from "./ProfileMetadataModel.js";

export class ProfileMetadata extends HTMLElement {
  constructor() {
    super();
    [this.el, this.$] = Template(`ProfileMetadata`);
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(this.el);
    this.shadowRoot.addEventListener(`profile-metadata-get-model`, (evt) => {
      evt.stopPropagation(); // We stop propagation, since we return the appropriate value, without needing to go further
      evt.detail.resolve(this.model);
    });
  }
  static getModel(target) {
    var resolve, reject;
    const p = new Promise(
      (r) => (resolve = r),
      (r) => (reject = r),
    );
    target.dispatchEvent(
      new CustomEvent(`profile-metadata-get-model`, {
        bubbles: true,
        cancelable: true,
        composed: true,
        detail: {
          resolve: resolve,
        },
      }),
    );
    return p;
  }
  connectedCallback() {
    this.model = new ProfileMetadataModel(
      document.getElementById(this.getAttribute(`target`)),
    );
  }
}

customElements.define("idserver-metadata", ProfileMetadata);
